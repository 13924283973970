import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout/Layout.vue";

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: Layout,
		redirect: '/home',
		children: [{
				path: '/home',
				name: 'home',
				component: () => import( /* webpackChunkName: "about" */ '../views/home.vue')
			},
			{
				path: '/fault-manag',
				name: 'fault-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/fault-manag.vue')
			},
			
			{
				path: '/book-manag',
				name: 'book-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/book-manag.vue')
			},
			{
				path: '/cash-flow-manag',
				name: 'cash-flow-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/cash-flow-manag.vue')
			},
			{
				path: '/clean-machine-manag',
				name: 'clean-machine-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/clean-machine-manag.vue')
			},

			{
				path: '/bank-cardbin-manag',
				name: 'bank-cardbin-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/bank-cardbin-manag.vue')
			},
			{
				path: '/cash-box-threshold-manag',
				name: 'cash-box-threshold-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/cash-box-threshold-manag.vue')
			},
			{
				path: '/cash-box-info',
				name: 'cash-box-info',
				component: () => import( /* webpackChunkName: "about" */ '../views/cash-box-info.vue')
			},
			{
				path: '/report-manag',
				name: 'report-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/report-manag.vue')
			},
			{
				path: '/equipment-info',
				name: 'equipment-info',
				component: () => import( /* webpackChunkName: "about" */ '../views/equipment-info.vue')
			},
			{
				path: '/equipment-status',
				name: 'equipment-status',
				component: () => import( /* webpackChunkName: "about" */ '../views/equipment-status.vue')
			},
			
			{
				path: '/banknotes',
				name: 'banknotes',
				component: () => import( /* webpackChunkName: "about" */ '../views/banknotes.vue')
			},

			{
				path: '/member-manag',
				name: 'member-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/member-manag.vue')
			},
			{
				path: '/member-audit-manag',
				name: 'member-audit-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/member-audit-manag.vue')
			},



			{
				path: '/coin-swap-info-manag',
				name: 'coin-swap-info-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/coin-swap-info-manag.vue')
			},
			{
				path: '/coin-swap-info-manag1',
				name: 'coin-swap-info-manag1',
				component: () => import( /* webpackChunkName: "about" */ '../views/coin-swap-info-manag1.vue')
			},
			{
				path: '/policy-info',
				name: 'policy-info',
				component: () => import( /* webpackChunkName: "about" */ '../views/policy-info.vue')
			},
			{
				path: '/policy-info1',
				name: 'policy-info1',
				component: () => import( /* webpackChunkName: "about" */ '../views/policy-info1.vue')
			},
			{
				path: '/info-release-security-manag',
				name: 'info-release-security-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/info-release-security-manag.vue')
			},
			{
				path: '/articles',
				name: 'articles',
				component: () => import( /* webpackChunkName: "about" */ '../views/articles.vue')
			},
			{
				path: '/article-class',
				name: 'article-class',
				component: () => import( /* webpackChunkName: "about" */ '../views/article-class.vue')
			},



			{
				path: '/order-manag',
				name: 'order-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/order-manag.vue')
			},
			{
				path: '/order-manag1',
				name: 'order-manag1',
				component: () => import( /* webpackChunkName: "about" */ '../views/order-manag1.vue')
			},
			{
				path: '/order-manag2',
				name: 'order-manag2',
				component: () => import( /* webpackChunkName: "about" */ '../views/order-manag2.vue')
			},
			{
				path: '/order-manag3',
				name: 'order-manag3',
				component: () => import( /* webpackChunkName: "about" */ '../views/order-manag3.vue')
			},
			{
				path: '/order-manag4',
				name: 'order-manag4',
				component: () => import( /* webpackChunkName: "about" */ '../views/order-manag4.vue')
			},


			{
				path: '/user-manag',
				name: 'user-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/user-manag.vue')
			},
			{
				path: '/department-manag',
				name: 'department-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/department-manag.vue')
			},
			{
				path: '/vendor-manag',
				name: 'vendor-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/vendor-manag.vue')
			},
			{
				path: '/bank-manag',
				name: 'bank-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/bank-manag.vue')
			},
			{
				path: '/bank-outlets',
				name: 'bank-outlets',
				component: () => import( /* webpackChunkName: "about" */ '../views/bank-outlets.vue')
			},
			{
				path: '/ads',
				name: 'ads',
				component: () => import( /* webpackChunkName: "about" */ '../views/ads.vue')
			},
			{
				path: '/router-manag',
				name: 'router-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/router-manag.vue')
			},
			{
				path: '/role-manag',
				name: 'role-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/role-manag.vue')
			},
			{
				path: '/change-password',
				name: 'change-password',
				component: () => import( /* webpackChunkName: "about" */ '../views/change-password.vue')
			},



			{
				path: '/equipment-manag',
				name: 'equipment-manag',
				component: () => import( /* webpackChunkName: "about" */ '../views/equipment-manag.vue')
			},
			{
				path: '/equipment-types',
				name: 'equipment-types',
				component: () => import( /* webpackChunkName: "about" */ '../views/equipment-types.vue')
			},
			{
				path: '/setting',
				name: 'setting',
				component: () => import( /* webpackChunkName: "about" */ '../views/setting.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
	},

]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {

	if (to.name !== 'login' && !localStorage.getItem("token")) {
		next({
			name: 'login'
		})
	} else {
		if (localStorage.getItem('authorityList')) {
			let authority = JSON.parse(localStorage.getItem('authorityList'))
			if (authority.includes('/' + to.name) || to.name == 'login') {
				next();
			} else {
				// next()
				if (to.name == 'home') {
					console.log(212)
					next({
						name: authority[0].slice(1)
					})
				} else if (to.name == 'login') {
					console.log(22)
					next({
						name: 'login'
					})
				} else {
					// Vue.prototype.$message.error('无权限访问');
					localStorage.removeItem('username')
					localStorage.removeItem('token')
					localStorage.removeItem('authority')
					localStorage.removeItem('authorityList')
					window.location.href = '#/login'
				}
			}
		} else {
			next()
		}
	}
})

export default router

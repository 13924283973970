<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
import Layout from "@/components/Layout/Layout.vue";

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  components: {
    Layout,
  },
};
</script>

<style lang="less">
	
	@media screen and (max-width: 1280px) {
	    body {
	        width: 1500px !important;
	    }
	}
	
body {
  height: 100%;
}
#app {
  height: 100%;
}

// antd
.ant-layout {
  height: 100%;
}
.ant-breadcrumb {
  background-color: #fff;
  padding: 10px !important;
}
.ant-advanced-search-form {
  border-radius: 0px !important;
  background: #fff !important;
  border: none !important;
  padding: 16px !important;
  margin-top: 16px !important;
}

.search-list-box {
  .search-result-list {
    background: #fff !important;
  }
  .ant-form-item-control-wrapper {
    max-width: 70%;
  }
  .ant-form-item-label {
    min-width: 110px;
    text-align: right;
  }
  .ant-select-enabled {
    width: 100% !important;
  }
  .ant-calendar-picker {
    width: 100% !important;
  }
}

.ant-input {
  border-radius: 2px !important;
}

.search-result-list {
  padding: 22px !important;
  padding-bottom: 0px !important;
}

.table-header-box {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  .left {
    display: flex;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #000;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}

.marginr20 {
  margin-left: 12px;
}

.model-footer {
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.fromdisflex .ant-form-item {
  display: flex;
}
.fromdisflex .ant-form-item-label {
  width: 130px;
  min-width: 130px;
}
.fromdisflex .ant-form-item-control-wrapper {
  width: 100%;
}
.ant-form .ant-form-item {
  margin-bottom: 14px;
}
.ant-table-tbody tr:nth-child(2n) {
  background: rgba(230, 247, 255, 0.5);
}
.ant-menu.ant-menu-inline-collapsed .titb {
  display: none;
}

.content-btns {
	display: flex;
	justify-content: center;
	width: 100%;
}


</style>
